import React from "react"
import Layout from "../../components/layout-ages"

export default () => (
  <Layout title="5 Year Old">
    <h2>5 Year Old</h2>
    <div className="clear-block">
      <div id="node-16" className="node">


        <div className="content clear-block">
          <p><img src="/images/005-caleb-270x203.jpg" className="float-right" width="270"
                  height="203" alt=""/></p>
          <p>If you are visiting this web site and have a 5 year old child you would like to start on piano; we hope you
            will give the Animal Note method serious consideration. Many established teachers will not accept a child
            who is under 8 or not in the third grade. They feel learning to note read is too difficult for younger
            children, and it would be impossible for them to use their hands correctly. Our Music Studio and our many
            satisfied customers have found the Animal Note method not only allows younger children to begin music, but
            also learn quickly and truly enjoy what they are learning and accomplishing.</p>
          <p><img src="/images/059-andy-200x267.jpg" className="float-left" width="200"
                  height="267" alt=""/> Five year olds are so eager to learn, and the timing is so good for them to start piano
            lessons. They have not yet gotten involved in too many out-of-school activities. They are learning math
            principals in their kindergarten class that can be applied to music (and sometimes vice-versa). They can
            concentrate for most of a 30 minute lesson, if it is varied and the atmosphere is positive. A child started
            at this age, using the Animal Note method, will have already built a strong foundation of note reading and
            timing; and will be well on their way to a life time of joy in piano by the time they celebrate their 8th
            birthday.</p>
          <p>Music helps in a young child’s development in so many ways; it is a gift of a life time, a truly precious
            gift. </p>
          <h3>Testimonial</h3>
          <p><i>"I am thrilled with your program! What a difference it has made to my younger students who were
            struggling with note names. Thank you!"<br/>
            - Sandy</i></p>
          <p><i>My daughters have both started lessons using the animal note reading and have done amazingly well. I
            have also enjoyed learning with this book. In school, I never understood how to read the music. It sounds
            silly, but it really does work and I have been almost as excited as they are to learn. Thank you for finding
            a way to make it fun for them. Thank you for finding a way to make it fun for them.<br/>
            - ELIZABETH ORGAN</i></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
